import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import { logout, isLoggedIn, reload, getUserRole } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  drawerIcon: {
    position: "absolute",
    right: theme.spacing(1),
  },
  drawerMenuItem: {
    justifyContent: "left",
    width: "100%",
  },
  drawerMenuToogleItem: {
    justifyContent: "left",
    width: "100%",
  },
  drawerMenuToogledItem: {
    justifyContent: "left",
    width: "100%",
    paddingLeft: theme.spacing(3),
  },
  hidden: {
    display: "none",
  },
  drawer: {
    width: 180,
    paddingTop: theme.spacing(1),
    background: "url(/img/logo-menu.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px 25px",
    minHeight: 280,
  },
  toogleIconContainer: {
    position: "absolute",
    right: theme.spacing(1),
    top: "6px",
    display: "inline-block",
  },
  logo: {
    height: 30,
    marginRight: theme.spacing(1),
    maxWidth: "90%",
  },
});

class OksMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerMenuOpen: false,
      intranetMenuOpen: false,
    };

    var currentMonth = new Date();

    this.menuItems = [
      { href: "/hjortgarden", text: "Hjortgården" },
      { href: "/klubben", text: "Klubben" },
      { href: "/traning", text: "Träning" },
      { href: "/tavling", text: "Tävling" },
      { href: "/arrangemang", text: "Arrangemang" },
      { href: "/borja-orientera", text: "Börja orientera" },
    ];

    this.intranetMenuItems = [
      { href: "/internt/information", text: "Information" },
      { href: "/internt/protokoll", text: "Protokoll" },
      { href: "/internt/skogshjort", text: "Skogshjort" },
    ];

    if (getUserRole() !== "Member") {
      this.intranetMenuItems.push({
        href: "/nyheter/skapa",
        text: "Skapa nyhet",
      });
      this.intranetMenuItems.push({
        href:
          "/kalender/" +
          currentMonth.getUTCFullYear() +
          "/" +
          (parseInt(currentMonth.getUTCMonth(), 10) + 1) +
          "/skapa",
        text: "Skapa aktiviteter",
      });
    }

    this.externalMenuItems = [
      {
        href: "https://www.facebook.com/groups/6938868938/",
        text: "OKS-Facebook",
      },
    ];

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  toggleDrawer(open) {
    this.setState({
      drawerMenuOpen: open,
    });
  }

  handleLogoutClick() {
    logout();
    reload();
  }

  handleClick() {
    this.setState({ intranetMenuOpen: !this.state.intranetMenuOpen });
  }

  render() {
    return (
      <AppBar position="static" color="inherit">
        <Toolbar>
          <Link to="/">
            <img
              src="/img/logo.svg"
              className={this.props.classes.logo}
              alt=""
            />
          </Link>
          <Hidden smDown>
            <span>
              {this.menuItems.map(function (item, index) {
                return (
                  <Button
                    component={Link}
                    key={index}
                    to={item.href}
                    onClick={() => (window.location.href = item.href)}
                  >
                    {item.text}
                  </Button>
                );
              }, this)}
            </span>
          </Hidden>
          <IconButton
            className={this.props.classes.drawerIcon}
            aria-label="Menu"
            onClick={() => this.toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Drawer
          anchor="right"
          open={this.state.drawerMenuOpen}
          onClose={() => this.toggleDrawer(false)}
        >
          <div className={this.props.classes.drawer}>
            <Hidden mdUp>
              <div>
                {this.menuItems.map(function (item, index) {
                  return (
                    <Button
                      key={index}
                      onClick={() => (window.location.href = item.href)}
                      component={Link}
                      className={this.props.classes.drawerMenuItem}
                      to={item.href}
                    >
                      {item.text}
                    </Button>
                  );
                }, this)}
              </div>
            </Hidden>
            {isLoggedIn() ? (
              <div>
                <Button
                  onClick={this.handleClick}
                  className={this.props.classes.drawerMenuToogleItem}
                >
                  Intranät
                  <div className={this.props.classes.toogleIconContainer}>
                    {this.state.intranetMenuOpen ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </div>
                </Button>
                <div
                  className={
                    this.state.intranetMenuOpen ? "" : this.props.classes.hidden
                  }
                >
                  {this.intranetMenuItems.map(function (item, index) {
                    return (
                      <Button
                        component={Link}
                        key={index}
                        className={this.props.classes.drawerMenuToogledItem}
                        to={item.href}
                        onClick={() => (window.location.href = item.href)}
                      >
                        {item.text}
                      </Button>
                    );
                  }, this)}
                </div>
              </div>
            ) : (
              ""
            )}
            {this.externalMenuItems.map(function (item, index) {
              return (
                <Button
                  key={index}
                  className={this.props.classes.drawerMenuItem}
                  href={item.href}
                  onClick={() => (window.location.href = item.href)}
                >
                  {item.text}
                </Button>
              );
            }, this)}
            {!isLoggedIn() ? (
              <Button
                component={Link}
                to="/login"
                className={this.props.classes.drawerMenuItem}
              >
                Logga in
              </Button>
            ) : (
              <Button
                className={this.props.classes.drawerMenuItem}
                onClick={this.handleLogoutClick}
              >
                Logga ut
              </Button>
            )}
          </div>
        </Drawer>
      </AppBar>
    );
  }
}

export default withStyles(styles)(OksMenu);
