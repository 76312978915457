import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { getLatestNews } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { NewsIcon } from "../Icons/Icons";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  link: {
    textTransform: "none",
    minWidth: "auto",
    minHeight: "auto",
    padding: 2,
    marginLeft: -2,
  },
  title: {
    marginTop: theme.spacing(1),
  },
  readMoreContainer: {
    marginTop: theme.spacing(2),
  },
  hidden: {
    display: "none",
  },
  readMoreLink: {
    marginTop: theme.spacing(1),
    textTransform: "none",
    width: "100px",
  },
  newsContainer: {
    marginBottom: theme.spacing(2),
  },
});

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      firstLoad: true,
    };
  }

  componentDidMount() {
    if (this.state.firstLoad) {
      this.props.isLoadingCallback("news", true);

      getLatestNews(10).then((response) => {
        this.setState({ data: response.data, firstLoad: false });
        this.props.isLoadingCallback("news", false);
      });
    }
  }

  render() {
    return (
      <Paper
        className={
          this.state.data.length > 0
            ? this.props.classes.paper
            : this.props.classes.hidden
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">
              NYHETER <NewsIcon size="20" />
            </Typography>

            {this.state.data &&
              this.state.data.map(function (item, index) {
                return (
                  <div key={index} className={this.props.classes.newsContainer}>
                    <Typography
                      variant="subtitle1"
                      className={this.props.classes.title}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="caption">
                      ({item.timestamp})
                    </Typography>
                    <Typography variant="body1">{item.description} </Typography>

                    {!item.hasContent && item.link && item.link.length > 0 ? (
                      <Button
                        className={this.props.classes.readMoreLink}
                        href={item.link}
                        color="secondary"
                        variant="outlined"
                      >
                        Länk
                      </Button>
                    ) : (
                      ""
                    )}
                    {item.hasContent ? (
                      <Button
                        className={this.props.classes.readMoreLink}
                        href={item.url}
                        color="secondary"
                        variant="outlined"
                      >
                        Läs mer
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                );
              }, this)}
          </Grid>
          <Grid item xs={12} className={this.props.classes.readMoreContainer}>
            <Button variant="outlined" href="/nyheter" color="secondary">
              LÄS FLER NYHETER
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(News);
