import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    disabled: {
        display: 'none'
    }
});

class Loader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: props.isLoading
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.isLoading === true && this.state.isLoading === false) {
            this.setState({ isLoading: true });
        } else if (this.props.isLoading === false && this.state.isLoading === true) {
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <div className={!this.state.isLoading ? this.props.classes.disabled : ''} >
                <LinearProgress />
            </div>
        )
    }
}

export default withStyles(styles)(Loader);