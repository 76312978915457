import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { getEventsForMonth, isLoggedIn, redirect } from '../../util/apicalls';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../Alert/Loader';
import ActivityList from './ActivityList';
import EditForm from './EditForm';
import Button from '@material-ui/core/Button';
import EditActivity from './EditActivity';

const styles = theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200
  },
  nextButtons: {
    marginTop: theme.spacing(2)
  }
});

class EditCalendar extends Component {

  constructor(props) {
    super(props)

    this.state = {
      route: '',
      name: '',
      eventType: 'training',
      isLoading: true,
      month: '',
      data: [],
      previousMonthUrl: '',
      nextMonthUrl: '',
      editEvent: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.editCallback = this.editCallback.bind(this);
    this.loadCurrentMonth = this.loadCurrentMonth.bind(this);
    this.edit = this.edit.bind(this);
    this.editDone = this.editDone.bind(this);
    this.getEventById = this.getEventById.bind(this);
  }

  componentDidMount() {
    if (!isLoggedIn()) {
      redirect('/login');
    }

    const route = window.location.pathname;
    if (route !== null) {
      if (this.state.route !== route) {
        this.setState({ route: route });
        this.loadCurrentMonth(route);
      };
    }
  }

  loadCurrentMonth(route) {
    var splittedRoute = route.split('/');

    var currentMonth = new Date(splittedRoute[2], splittedRoute[3]);
    this.setState(
      {
        previousMonthUrl: this.getCalendarUrl(currentMonth.getUTCFullYear(), parseInt(currentMonth.getUTCMonth(), 10)),
        nextMonthUrl: this.getCalendarUrl(currentMonth.getUTCFullYear(), parseInt(currentMonth.getUTCMonth() + 2, 10))
      })

    getEventsForMonth(splittedRoute[2], splittedRoute[3]).then(response => {
      this.setState({ data: response.data.events, month: response.data.month, isLoading: false });
    });
  }

  edit(eventId) {
    let eventToEdit = this.getEventById(eventId);
    this.setState({ editEvent: eventToEdit });
    //scroll(0, 0);
  }

  getEventById(eventId) {
    var eventsInArrays = this.state.data.map(function (day) {
      return day.events;
    })

    var allEvents = [];

    eventsInArrays.forEach(element => {
      allEvents = [...allEvents, ...element];
    });

    return allEvents.find(x => x.id === eventId);
  }

  editDone() {
    this.setState({ editDone: null });
  }

  handleChange(event, name) {
    this.setState({
      [name]: event.target.value,
    });
  };

  getCalendarUrl(year, month) {
    if (month === 0) {
      year = parseInt(year, 10) - 1;
      month = 12;
    } else if (month === 13) {
      year = parseInt(year, 10) + 1;
      month = 1;
    }

    return '/kalender/' + year + '/' + month + '/skapa';
  }

  editCallback(isLoading) {
    this.setState({ isLoading: isLoading });

    if (!isLoading) {
      this.loadCurrentMonth(this.state.route);
    }
  }

  render() {

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Loader isLoading={this.state.isLoading} />
          <Typography variant="h1">{this.state.month}</Typography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button variant='contained' color="primary" href={this.state.previousMonthUrl}>Föreg. månad</Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button variant='contained' color="primary" href={this.state.nextMonthUrl}>Nästa månad</Button>
        </Grid>
        <Grid item sm={6}>
        </Grid>
        <Grid item xs={12} sm={6}>
          <EditForm isSaving={this.editCallback} route={window.location.pathname} editEvent={this.state.editEvent} editDone={this.editDone} />
          <br /> <br />
          <EditActivity activities={this.state.data} edit={this.edit} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ActivityList data={this.state.data} fullPage={true} />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(EditCalendar);