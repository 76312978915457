import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { CheckStrokeIcon } from '../Icons/Icons';
import { getTrainingGroups } from '../../util/apicalls';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    legend: {        
        verticalAlign: 'middle'
    },
    legendContainer: {
        marginTop: theme.spacing(1)
    }
});

class Legend extends Component {
    render() {
        return (
            <Grid container className={this.props.classes.legendContainer}>
                {getTrainingGroups().map(function (trainingGroup, index) {
                    return (
                        <Grid item sm={6} md={4} key={index}>
                            <Typography variant="body1" className={this.props.classes.legend}>
                                <CheckStrokeIcon size='13' rgbColor={trainingGroup.key} /> = {trainingGroup.value}
                            </Typography>
                        </Grid>
                    )
                }, this)}
            </Grid>
        )
    }
}

export default withStyles(styles)(Legend);