import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Day from './Day';

const styles = theme => ({
    rootAsPartial: {
        background: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 400,
        width: '100%'
    },
    rootAsFullPage: {
        background: theme.palette.background.paper,
        position: 'relative',
        width: '100%'
    }    
});

class ActivityList extends Component {
    render() {
        return (
            <List className={this.props.fullPage ? this.props.classes.rootAsFullPage : this.props.classes.rootAsPartial} subheader={<div />}>
                {this.props.data.map(function (day, index) {
                    return (
                        <Day fullPage={this.props.fullPage} key={`section-${day.date}`} day={day} />
                    )
                }, this)}
            </List>

        )
    }
}

export default withStyles(styles)(ActivityList);