import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { registerUser, isLoggedIn, redirect } from '../../util/apicalls'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loader from '../Alert/Loader';
import Popup from '../Alert/Popup';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(4)
    },
    row: {
        textAlign: 'center',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    button: {
        margin: theme.spacing(1)
    },
    info: {
        textAlign: 'left',
        margin: 'auto',
        maxWidth: 400
    }
});

class Register extends Component {

    constructor(props) {
        super(props)

        this.state = {
            mail: '',
            key: '',
            password: '',
            firstName: '',
            lastName: '',
            isLoading: false,
            openAlert: false,
            alertMessage: '',
            isError: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        if (isLoggedIn()) {
            redirect('/');
        }

        const route = window.location.pathname;
        if (route !== null && this.state.mail === '') {

            var splittedRoute = route.split('/');

            this.setState(
                {
                    mail: decodeURIComponent(splittedRoute[2]),
                    key: decodeURIComponent(splittedRoute[3])
                });
        }
    }

    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    };

    save(value) {

        if (
            this.state.firstName.length === 0 ||
            this.state.lastName.length === 0 ||
            this.state.password.length < 4
        ) {
            this.setState({ isError: true });
            return;
        }

        this.setState({ isLoading: true });

        registerUser(this.state.mail, this.state.password, this.state.key, this.state.firstName, this.state.lastName).then(result => {
            if (result) {
                this.setState({
                    mail: '',
                    firstName: '',
                    lastName: '',
                    key: '',
                    password: '',
                    openAlert: true,
                    alertMessage: 'Du är nu registrerad, välkommen att logga in!'
                });
            } else {
                this.setState({ openAlert: true, alertMessage: 'Något gick fel, har du en korrekt länk?' });
            }

            this.setState({ isLoading: false });
        })
    }

    closePopup() {
        this.setState({ openAlert: false });
    }

    render() {
        return (
            <div className={this.props.classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        <Loader isLoading={this.state.isLoading} />
                        <Typography variant="h1">Registrera användare</Typography>
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.row}>
                        <TextField
                            required
                            error={this.state.isError && this.state.password.length < 4}
                            id="password"
                            label="Lösenord"
                            placeholder="Ange lösenord"
                            className={this.props.classes.textField}
                            type="password"
                            margin="normal"
                            onChange={(e) => this.handleChange(e, 'password')}                           
                        />
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.row}>
                        <TextField
                            required
                            error={this.state.isError && this.state.firstName.length === 0}
                            id="firstName"
                            label="Förnamn"
                            placeholder="Ange förnamn"
                            className={this.props.classes.textField}
                            margin="normal"
                            onChange={(e) => this.handleChange(e, 'firstName')}
                        />
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.row}>
                        <TextField
                            required
                            error={this.state.isError && this.state.lastName.length === 0}
                            id="lastName"
                            label="Efternamn"
                            placeholder="Ange efternamn"
                            className={this.props.classes.textField}
                            margin="normal"
                            onChange={(e) => this.handleChange(e, 'lastName')}
                        />
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.row}>
                        <Typography variant="body1" className={this.props.classes.info} gutterBottom>
                            Vid registrering godkänner du att e-post och ditt namn lagras.
                            Vi kommer att använda e-post för inloggning och för utskick av hemsiderelaterad information.
                            Namnet använder vi för att vi ska veta vem som har skapat innehåll och nyheter.<br /><br />
                            Kontot måste förnyas minst vartannat år. Om du vill ha ut vilken information som lagras om dig eller om du vill ta bort dig själv,
                            maila webmaster@skogshjortarna.com så återkommer klubben inom en månad.<br /><br />
                            Undvik alltid i största mån att publicera personuppgifter på hemsidan.
                            Om detta görs förutsätts att ni har godkännande från aktuell person.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={this.props.classes.row}>
                        <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.save}>
                            Slutför registrering
                        </Button>
                    </Grid>
                </Grid>
                <Popup message={this.state.alertMessage} open={this.state.openAlert} onClosing={this.closePopup} />
            </div >
        )
    }
}

export default withStyles(styles)(Register);