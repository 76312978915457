import React from "react";
import Grid from "@material-ui/core/Grid";
import Sponsors from "./components/Layout/Sponsors";
import OksMenu from "./components/Layout/Menu";
import { withStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Route, Routes } from "react-router-dom";
import theme from "./theme";

import Home from "./components/Home/Home";
import NotFound from "./components/General/NotFound";
import Content from "./components/Content/Content";
import Login from "./components/Login/Login";
import NewsList from "./components/News/NewsList";
import News from "./components/News/News";
import Month from "./components/Calendar/Month";
import EditCalendar from "./components/Calendar/EditCalendar";
import CreateNewsForm from "./components/News/CreateNewsForm";
import RecoverPassword from "./components/Login/RecoverPassword";
import ResetPassword from "./components/Login/ResetPassword";
import Register from "./components/Login/Register";
import CreateUser from "./components/Admin/CreateUser";
import EditContent from "./components/Content/EditContent";

const styles = (theme) => ({
  rootContainer: {
    padding: 0,
  },
});

class App extends React.Component {
  componentDidMount() {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg" className={this.props.classes.rootContainer}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <OksMenu />
            </Grid>
            <Grid item xs={12}>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/vilse" element={<NotFound />} />
                <Route
                  exact
                  path="/vimsig/:email/:key"
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path="/tillstart/:email/:key"
                  element={<Register />}
                />
                <Route exact path="/kalender" element={<Month />} />
                <Route exact path="/kalender/:year/:month" element={<Month />} />
                <Route
                  exact
                  path="/kalender/:year/:month/skapa"
                  element={<EditCalendar />}
                />
                <Route exact path="/nyheter" element={<NewsList />} />
                
                <Route
                  exact
                  path="/nyheter/skapa"
                  element={<CreateNewsForm />}
                />
                <Route exact path="/nyheter/:year/:id" element={<News />} />
                <Route exact path="/nyheter/:year" element={<NewsList />} />
                <Route
                  exact
                  path="/internt/:id/redigera"
                  element={<EditContent />}
                />
                <Route exact path="/internt/:id" element={<Content />} />
                <Route exact path="/login" element={<Login />} />
                <Route
                  exact
                  path="/login/glomsk"
                  element={<RecoverPassword />}
                />
                <Route
                  exact
                  path="/admin/skapa-medlem"
                  element={<CreateUser />}
                />
                <Route exact path="/:id/redigera" element={<EditContent />} />
                <Route exact path="/:id" element={<Content />} />
              </Routes>
            </Grid>
            <Grid item xs={12}>
              <Sponsors />
            </Grid>
          </Grid>
        </Container>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
