import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary
    },
    logo: {
        textAlign: 'center'
    }
});

class Sponsors extends Component {
    constructor(props) {
        super(props)

        this.partners = [
            { img: 'maxi.png', link: 'https://www.ica.se/butiker/maxi/alingsas/maxi-ica-stormarknad-alingsas-1841/start/' },
            { img: 'allebike.png', link: 'http://allebike.se/' },
            { img: 'centraltryckeriet.png', link: 'https://centraltryckeriet-alingsas.se/' },
            { img: 'smsport.png', link: 'https://smsport.se/' },
            { img: 'swedbank.jpg', link: 'https://www.sparbankenalingsas.se/' },
            { img: 'ullmax.png', link: 'https://www.ullmax.com/se' },
            { img: 'borjessons_bil.png', link: 'http://www.borjessonsbil.se/' },
            { img: 'vimpeln.png', link: 'http://vimpelnalingsas.se/' }
        ];
    }

    render() {
        return (
            <Paper className={this.props.classes.paper}>
                <Grid container alignItems='center' justify='space-around'>
                    <Grid item xs={12}>
                        <Typography variant="h2">SAMARBETSPARTNERS</Typography>
                    </Grid>
                    {this.partners && this.partners.map(function (partner, index) {
                        return (
                            <Grid className={this.props.classes.logo} key={index} item xs={6} sm={4} lg={3}>
                                <a href={partner.link}><img src={`/img/partners/${partner.img}`} alt='' /></a>
                            </Grid>
                        )
                    }, this)}
                </Grid>
            </Paper>
        )
    }
}

export default withStyles(styles)(Sponsors);