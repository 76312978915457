import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getNews } from "../../util/apicalls";
import Loader from "../Alert/Loader";
import DynamicContent from "../Editor/DynamicContent";
import Button from "@material-ui/core/Button";
import OneColumnContainer from "../OneColumnContainer";

const styles = (theme) => ({
  row: {
    paddingTop: theme.spacing(2),
  },
  description: {
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: "left",
  },
  link: {
    textTransform: "none",
    minWidth: "auto",
    minHeight: "auto",
    padding: 2,
    marginLeft: -2,
  },
  hidden: {
    display: "none",
  },
});

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      route: "",
      isLoading: true,
      data: {
        title: "",
        description: "",
        link: "",
        content: {
          ops: [],
        },
      },
    };
  }

  componentDidMount() {
    const route = window.location.pathname;
    if (route !== null && route !== this.state.route) {
      if (this.state.route !== route) {
        this.setState({ route: route });

        var splittedRoute = route.split("/");

        getNews(splittedRoute[2], splittedRoute[3]).then((response) => {
          this.setState({
            data: {
              title: response.data.title,
              description: response.data.description,
              content: JSON.parse(response.data.content),
              link: response.data.link,
            },
            isLoading: false,
          });
        });
      }
    }
  }

  render() {
    return (
      <OneColumnContainer>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1" className={this.props.classes.title}>{this.state.data.title}</Typography>

            <Typography
              className={this.props.classes.description}
              variant="body2"
            >
              {this.state.data.description}
            </Typography>
            {this.state.data.link && this.state.data.link.length > 0 ? (
              <Button
                color="secondary"
                href={this.state.data.link}
                className={this.props.classes.link}
              >
                LÄNK
              </Button>
            ) : (
              ""
            )}
            {this.state.data.content &&
            this.state.data.content.ops &&
            this.state.data.content.ops.length > 0 ? (
              <DynamicContent delta={this.state.data.content} />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </OneColumnContainer>
    );
  }
}

export default withStyles(styles)(News);
