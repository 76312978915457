import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../Alert/Loader";
import OneColumnContainer from "../OneColumnContainer";
import Popup from "../Alert/Popup";
import DynamicContent from "../Editor/DynamicContent";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {
  isLoggedIn,
  getUserRole,
  getContent,
  redirect,
} from "../../util/apicalls";

const styles = (theme) => ({
  row: {
    paddingTop: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(2),
  },
  edit: {
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  title: {
    textAlign: "left",
  },
});

class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      openAlert: false,
      data: {
        title: "",
        description: "",
        content: [],
      },
      viewEditButton: false,
    };

    this.loadData = this.loadData.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    this.loadData(window.location.pathname);
  }

  closePopup() {
    this.setState({ openAlert: false });
  }

  loadData(route) {
    var defaultData = {
      title: "",
      description: "",
      content: [],
    };

    this.setState({ isLoading: true, data: defaultData });

    getContent(route)
      .then((response) => {
        if (response.data.content == null) {
          response.data.content = [
            { leftContent: { ops: [] }, rightContent: { ops: [] } },
          ];
        }

        this.setState({
          data: {
            title: response.data.title,
            description: response.data.description,
            content: JSON.parse(response.data.content),
          },
          isLoading: false,
          viewEditButton: isLoggedIn() && getUserRole() !== "Member",
        });
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 404
        ) {
          redirect("/vilse");
        }

        this.setState({ openAlert: true });
      });
  }

  render() {
    return (
      <OneColumnContainer>
        <Grid container>
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1" className={this.props.classes.title}>
              {this.state.data.title}
            </Typography>
          </Grid>
          <Popup
            message="Innehållet gick inte att ladda"
            open={this.state.openAlert}
            onClosing={this.closePopup}
          />
        </Grid>

        {this.state.data.content &&
          this.state.data.content.map(function (item, index) {
            return (
              <Grid
                key={index}
                container
                spacing={2}
                justify="center"
                alignItems="stretch"
                className={this.props.classes.root}
              >
                <Grid item xs={12} className={this.props.classes.row}>
                  <DynamicContent delta={item.leftContent} />
                  <DynamicContent delta={item.rightContent} />
                </Grid>
              </Grid>
            );
          }, this)}

        <Grid
          container
          className={
            this.state.viewEditButton !== true
              ? this.props.classes.hidden
              : this.props.classes.edit
          }
        >
          <Button
            href={`${window.location.pathname}/redigera`}
            variant="outlined"
            color="secondary"
            aria-label="edit"
            className={this.props.classes.button}
          >
            <EditIcon />
          </Button>
        </Grid>
      </OneColumnContainer>
    );
  }
}

export default withStyles(styles)(Content);
