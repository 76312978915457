import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { createUser, getUserRole, isLoggedIn, redirect } from '../../util/apicalls';
import Popup from '../Alert/Popup';
import Paper from '@material-ui/core/Paper';
import Loader from '../Alert/Loader';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary
    }
});

class CreateUser extends Component {

    constructor(props) {
        super(props)

        this.state = {
            mailAddress: '',
            userRole: 'Member',
            popupMessage: '',
            isLoading: false,
            openAlert: false,
            isError: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    componentDidMount() {
        if (!isLoggedIn() || getUserRole() !== 'Webmaster') {
            redirect('/');
        }
    }

    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    };

    save() {
        if (this.state.mailAddress.length === 0) {
            this.setState({ isError: true });
            return;
        }

        this.setState({ isLoading: true, isError: false });

        createUser(this.state.mailAddress, this.state.userRole)
            .then(response => {
                this.setState({
                    mailAddress: '',
                    isLoading: false,
                    popupMessage: 'Användare är skapad',
                    openAlert: true
                });
            })
            .catch(response => {
                this.setState({
                    mailAddress: '',
                    isLoading: false,
                    popupMessage: 'Något gick fel, finns redan användaren?',
                    openAlert: true
                });
            });
    }

    closePopup() {
        this.setState({ openAlert: false });
    }

    render() {

        return (
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Loader isLoading={this.state.isLoading} />
                    <Typography variant="h1">Skapa användare</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Paper className={this.props.classes.paper}>
                        <Grid container justify="center">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    error={this.state.isError}
                                    id="mailAddress"
                                    label="E-post"
                                    className={this.props.classes.textField}
                                    onChange={(e) => this.handleChange(e, 'mailAddress')}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="userRole"
                                    select
                                    label="Behörighet"
                                    className={this.props.classes.textField}
                                    value={this.state.userRole}
                                    SelectProps={{
                                        MenuProps: {
                                            className: this.props.classes.menu,
                                        },
                                    }}
                                    onChange={(e) => this.handleChange(e, 'userRole')}
                                    margin="normal"
                                >
                                    <MenuItem value='Member'>Medlem</MenuItem>
                                    <MenuItem value='Publisher'>Skribent</MenuItem>
                                    <MenuItem value='Webmaster'>Webmaster</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.save}>
                                    Skapa
                                </Button>
                            </Grid>
                        </Grid>
                        <Popup message={this.state.popupMessage} open={this.state.openAlert} onClosing={this.closePopup} />
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(CreateUser);