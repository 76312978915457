import { createMuiTheme } from "@material-ui/core/styles";
import red from "@material-ui/core/colors/red";
import yellow from "@material-ui/core/colors/yellow";

const theme = createMuiTheme({
  palette: {
    primary: yellow,
    secondary: red,
  },
  typography: {
    h1: {
      fontFamily: "Passion One",
      fontWeight: 400,
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "2.5em",
    },
    subtitle1: {
      fontFamily: "Passion One",
      fontWeight: 400,
    },
    h2: {
      fontFamily: "Passion One",
      fontWeight: 400,
      fontSize: "1.3125em",
      marginBottom: 8,
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "Passion One",
      fontWeight: 400,
      fontSize: "1rem",
      textTransform: "uppercase",
    },
    h4: {
      fontFamily: "Passion One",
      fontWeight: 400,
      fontSize: "1.3125em",
      borderBottomColor: yellow[300],
      marginBottom: 8,
      borderBottomStyle: "solid",
      borderBottomWidth: 2,
      textTransform: "uppercase",
    },
    subtitle1: {
      fontFamily: "Passion One",
      fontWeight: 400,
      textTransform: "uppercase",
    },
    body1: {
      fontSize: "0.875rem",
      overflowWrap: "break-word",
    },
  },
});

export default theme;
