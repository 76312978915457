import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { getUpcomingEvents } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import ActivityList from "../Calendar/ActivityList";
import News from "./News";
import Events from "./Events";
import Competitions from "./Competitions";
import Paper from "@material-ui/core/Paper";
import { MeetingIcon } from "../Icons/Icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Legend from "../Calendar/Legend";
import Popup from "../Alert/Popup";

const styles = (theme) => ({
  hidden: {
    display: "none",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  readMoreContainer: {
    marginTop: theme.spacing(1),
  },
  headline: {
    marginBottom: theme.spacing(1),
  },
});

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarData: [],
      firstLoad: true,
      openAlert: false,
    };

    this.isLoadingCallback = this.isLoadingCallback.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.viewErrorPopup = this.viewErrorPopup.bind(this);
  }

  componentDidMount() {
    if (this.state.firstLoad) {
      getUpcomingEvents(7)
        .then((response) => {
          this.setState({
            calendarData: response.data,
          });
        })
        .catch((error) => {
          this.viewErrorPopup();
        });

      this.setState({ firstLoad: false });
    }
  }

  viewErrorPopup() {
    if (!this.state.openAlert) {
      this.setState({
        openAlert: true,
        firstLoad: false,
      });
    }
  }

  isLoadingCallback(component, status) {}

  closePopup() {
    this.setState({ openAlert: false });
  }

  render() {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <News isLoadingCallback={this.isLoadingCallback} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper
            className={
              !this.state.isCalendarLoading
                ? this.props.classes.paper
                : this.props.classes.hidden
            }
          >
            <Typography variant="h4" className={this.props.classes.headline}>
              KALENDER <MeetingIcon size="20" />
            </Typography>
            <ActivityList
              className={
                this.state.calendarData.length > 0
                  ? ""
                  : this.props.classes.hidden
              }
              data={this.state.calendarData}
              fullPage
            />
            <Typography
              variant="body1"
              className={
                this.state.calendarData.length === 0
                  ? ""
                  : this.props.classes.hidden
              }
            >
              Inga kommande händelser
            </Typography>
            <div className={this.props.classes.readMoreContainer}>
              <Button color="secondary" href="/kalender" variant="outlined">
                GÅ TILL KALENDER
              </Button>
            </div>
            <Legend />
          </Paper>
          <Events />
          <Competitions isLoadingCallback={this.isLoadingCallback} />
          <Popup
            message="Just nu går det inte att visa hemsidan"
            open={this.state.openAlert}
            onClosing={this.closePopup}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Home);
