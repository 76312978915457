import { BrowserRouter } from "react-router-dom";
import React from "react";
import * as ReactDOM from 'react-dom';
import App from "./App";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root'),
);