/* Taken svg from https://materialdesignicons.com/ */
/* Required props rgbColor, size */
import React from 'react';

export function RunnerIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path stroke='grey' fill={props.rgbColor} d="M16.5,5.5A2,2 0 0,0 18.5,3.5A2,2 0 0,0 16.5,1.5A2,2 0 0,0 14.5,3.5A2,2 0 0,0 16.5,5.5M12.9,19.4L13.9,15L16,17V23H18V15.5L15.9,13.5L16.5,10.5C17.89,12.09 19.89,13 22,13V11C20.24,11.03 18.6,10.11 17.7,8.6L16.7,7C16.34,6.4 15.7,6 15,6C14.7,6 14.5,6.1 14.2,6.1L9,8.3V13H11V9.6L12.8,8.9L11.2,17L6.3,16L5.9,18L12.9,19.4M4,9A1,1 0 0,1 3,8A1,1 0 0,1 4,7H7V9H4M5,5A1,1 0 0,1 4,4A1,1 0 0,1 5,3H10V5H5M3,13A1,1 0 0,1 2,12A1,1 0 0,1 3,11H7V13H3Z" />
        </svg>
    )
}

export function SnowFlakeIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M20.79,13.95L18.46,14.57L16.46,13.44V10.56L18.46,9.43L20.79,10.05L21.31,8.12L19.54,7.65L20,5.88L18.07,5.36L17.45,7.69L15.45,8.82L13,7.38V5.12L14.71,3.41L13.29,2L12,3.29L10.71,2L9.29,3.41L11,5.12V7.38L8.5,8.82L6.5,7.69L5.92,5.36L4,5.88L4.47,7.65L2.7,8.12L3.22,10.05L5.55,9.43L7.55,10.56V13.45L5.55,14.58L3.22,13.96L2.7,15.89L4.47,16.36L4,18.12L5.93,18.64L6.55,16.31L8.55,15.18L11,16.62V18.88L9.29,20.59L10.71,22L12,20.71L13.29,22L14.7,20.59L13,18.88V16.62L15.5,15.17L17.5,16.3L18.12,18.63L20,18.12L19.53,16.35L21.3,15.88L20.79,13.95M9.5,10.56L12,9.11L14.5,10.56V13.44L12,14.89L9.5,13.44V10.56Z" />
        </svg>
    )
}

export function MeetingIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z" />
        </svg>
    )
}

export function PencilIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M16.84,2.73C16.45,2.73 16.07,2.88 15.77,3.17L13.65,5.29L18.95,10.6L21.07,8.5C21.67,7.89 21.67,6.94 21.07,6.36L17.9,3.17C17.6,2.88 17.22,2.73 16.84,2.73M12.94,6L4.84,14.11L7.4,14.39L7.58,16.68L9.86,16.85L10.15,19.41L18.25,11.3M4.25,15.04L2.5,21.73L9.2,19.94L8.96,17.78L6.65,17.61L6.47,15.29" />
        </svg>
    )
}

export function RectangleIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor1} d="M12,0L18,6L6,18L0,12Z" />
            <path fill={props.rgbColor2} d="M18,6L24,12L12,24L6,18Z" />
            <path stroke='black' fill='none' d="M12,0L24,12L12,24L0,12Z" />
        </svg>
    )
}

export function GolfIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M19.5,18A1.5,1.5 0 0,1 21,19.5A1.5,1.5 0 0,1 19.5,21A1.5,1.5 0 0,1 18,19.5A1.5,1.5 0 0,1 19.5,18M17,5.92L11,9V18.03C13.84,18.19 16,19 16,20C16,21.1 13.31,22 10,22C6.69,22 4,21.1 4,20C4,19.26 5.21,18.62 7,18.27V20H9V2L17,5.92Z" />
        </svg>
    )
}

export function DeerIcon(props) {
    var style = {
        width: props.width + 'px',
        height: props.height + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 264 139'>
            <path fill={props.rgbColor} d="m144.7,20.8c0.4,1.7 4.1,2.4 7.5,1.5c6,-2.4 12.1,-5.3 17.2,-8.4c0.5,-0.6 0.8,-0.2 0.4,0.4c-3.7,3 -7.9,8.5 -7.2,10.1c0,1.2 2.2,2.8 4.3,3.1c3.6,0.8 8.3,1.2 10.8,1.2c3.8,0 12.9,-1.3 16.8,-2.7c-0.2,0.2 0.1,0 0.3,0.4c-2.9,1.5 -5,4 -4.4,4.9c0.2,1.5 4.8,6.2 9.2,9.2c0.6,0.4 0.2,0.8 -0.3,0.3c-7.3,-4 -27,-9.3 -41.8,-11.9c-6.9,-1.5 -23.4,-3.4 -33.1,-3.8c-8,-0.6 -27.4,2.8 -38.6,7.8c0.3,0.4 0.8,2 0.9,3c4.7,1 10,1.8 13.4,5.7c-3.1,3.4 -8.8,3.2 -13.4,2.8c-1.1,4.3 -3.2,8.2 -5.4,11.9c-0.5,0.7 0.1,1.8 1.2,2.2c13.1,-0.2 23.2,-1 36.5,0.2c11.1,1.2 20.8,2.4 31.8,4.1c10.4,1.8 19.5,-2.4 25.6,-11.3c0.7,1.7 1,3.8 1.3,5.7c0.1,1.4 -1.2,3.9 -2.6,4.9c4.8,11.2 22.1,28.5 33.8,35.7c4,2.8 17.8,11.2 27.3,17c4.4,1.5 9.2,1.2 13.5,0.9c-0.9,2.5 -1.2,6.2 -0.6,7.2c1.8,1.8 6.2,7.6 8.3,11.2c0.1,0.4 -0.7,0.1 -0.5,0.3c-8.8,-5.5 -17.8,-12 -19.7,-13.5c-8.2,-5.4 -22.6,-13.4 -26.8,-16c-14.3,-9.2 -33.5,-17.2 -39,-16.6c-15.1,-1.9 -31,0.7 -45.8,4c-9.6,1.2 -20,2.7 -29.3,1.9c-5.9,-0.1 -12.2,-1.4 -17.9,-2.2c-4,-0.9 -7.9,-2.4 -11.9,-3.3c-0.7,-0.2 -1.5,4.8 -1,9.7c-0.7,1 -1.2,0.2 -1.7,0.1c-13.9,-4 -28.4,-6.4 -44.1,-5.9c3.7,4.6 13.7,12.5 19.4,15.9c4.4,3.1 12.3,5 16.4,3.5c2.6,-2.5 4.5,-4 3.7,-3.5c1.1,3.4 3.9,6.9 3.4,7.2c5.7,1.3 8,0.6 10.1,0.4c2,2.1 6.3,6.7 8.7,9.3c0,0.4 -0.3,0.4 -0.6,0.6c-5.6,-1.7 -13.7,-4 -16.3,-4.6c-1.5,0.3 -5.6,-0.4 -10,-0.9c-1.6,0.2 -7.1,-2.2 -11,-4.8c-10.9,-5.3 -21,-12.4 -32.2,-17c-3.2,-1.2 -6.6,-3.2 -8.3,-6.4c-0.5,-2.5 0.6,-4.9 2.3,-6.5c3.1,-1.3 16.2,-4.4 26.2,-6.3c2.9,-9.7 12.2,-15.8 19.3,-21.1c2.9,-2 6.4,-3.5 8.6,-6.4c0,-0.9 -0.4,-1.7 -1.1,-2.1c-5.4,-1.9 -10.4,-4.6 -16,-6.2c-1,-0.2 -2.6,0.6 -3.3,-0.2c-0.5,-1.7 -0.3,-3.6 -0.2,-5.4c2.5,-2.1 5.5,-2.8 8.4,-3.6c11.3,-3.5 22.8,-4.6 34.6,-6.9c7.8,-1 21.4,-4.8 27.1,-7.8c7.5,-4 14.2,-8.2 22.8,-14.6c0.6,-0.6 0.7,-0.3 0.7,0.2c-5.6,5 -8.7,10.7 -8.2,10.2c-0.9,1.3 -0.6,3.4 1.1,4.1c1.8,0.9 5.7,1 8,0.2c3.5,-1.2 12.3,-6.5 17.5,-10.7c0.5,-0.8 0.9,-0.3 0.6,0.4c-3.8,4.2 -7,9.4 -6.7,11.2z" />
        </svg>
    )
}

export function FileIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    )
}

export function DownloadIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
            <path d="M0 0h24v24H0z" fill="none" />
        </svg>
    )
}

export function NewsIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M20,2A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H6L2,22V4C2,2.89 2.9,2 4,2H20M4,4V17.17L5.17,16H20V4H4M6,7H18V9H6V7M6,11H15V13H6V11Z" />
        </svg>
    )
}

export function CopyIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
        </svg>
    )
}

export function CheckIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
        </svg>
    )
}

export function PinIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
        </svg>
    )
}

export function ImageIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path fill={props.rgbColor} d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z" />
        </svg>
    )
}

export function OctogramIcon(props) {
    var style = {
        width: props.size + 'px',
        height: props.size + 'px'
    }

    return (
        <svg style={style} viewBox='0 0 24 24'>
            <path stroke='grey' fill={props.rgbColor} d="M2.2,16.06L3.88,12L2.2,7.94L6.26,6.26L7.94,2.2L12,3.88L16.06,2.2L17.74,6.26L21.8,7.94L20.12,12L21.8,16.06L17.74,17.74L16.06,21.8L12,20.12L7.94,21.8L6.26,17.74L2.2,16.06Z" />
        </svg>
    )
}


export function CheckStrokeIcon(props) {
  var style = {
      width: props.size + 'px',
      height: props.size + 'px'
  }

  return (
      <svg style={style} viewBox='0 0 24 24'>
          <path stroke='grey'  fill={props.rgbColor} d="M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z" />
      </svg>
  )
}
