import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { login, isLoggedIn, redirect } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../Alert/Loader";
import Popup from "../Alert/Popup";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  row: {
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
  info: {
    maxWidth: 250,
    margin: "auto",
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail: "",
      password: "",
      isLoading: false,
      openErrorAlert: false,
      isError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onMailChange = this.onMailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    if (isLoggedIn()) {
      redirect("/");
    }
  }

  handleChange() {
    if (this.state.mail.length === 0 || this.state.password.length === 0) {
      this.setState({ isError: true });
      return;
    }

    this.setState({ isLoading: true });

    login(this.state.mail, this.state.password).then((result) => {
      if (result) {
        redirect("/");
      } else {
        this.setState({ openErrorAlert: true });
      }

      this.setState({ isLoading: false });
    });
  }
  onMailChange(event) {
    this.setState({ mail: event.target.value });
  }
  onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  closePopup() {
    this.setState({ openErrorAlert: false });
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1">Inloggning intranät</Typography>
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <TextField
              required
              error={this.state.isError && this.state.mail.length === 0}
              id="mail"
              label="Ange e-post"
              placeholder="Ange e-post"
              className={this.props.classes.textField}
              margin="normal"
              onChange={this.onMailChange}
            />
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <TextField
              required
              error={this.state.isError && this.state.password.length === 0}
              id="password"
              label="Ange lösenord"
              placeholder="Ange lösenord"
              className={this.props.classes.textField}
              type="password"
              autoComplete="current-password"
              margin="normal"
              onChange={this.onPasswordChange}
            />
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              onClick={this.handleChange}
            >
              Logga in
            </Button>
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <Typography variant="body1" className={this.props.classes.info}>
              <Button href="/login/glomsk" color="secondary">
                Glömt lösenord? klicka här
              </Button>
            </Typography>
            <Typography variant="body1" className={this.props.classes.info}>
              Om du saknar konto, skicka e-post till
              webmaster@skogshjortarna.com. Vid registrering lagras ditt namn
              och din e-post. Maila även om du vill bli borttagen. <br />
              <br />
              <br />
            </Typography>
          </Grid>
        </Grid>
        <Popup
          message="Inloggning misslyckades"
          open={this.state.openErrorAlert}
          onClosing={this.closePopup}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Login);
