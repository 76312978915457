import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getEventsForMonth, redirect } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import Loader from "../Alert/Loader";
import ActivityList from "./ActivityList";
import Button from "@material-ui/core/Button";
import Legend from "./Legend";
import OneColumnContainer from "../OneColumnContainer";

const styles = (theme) => ({
  link: {
    color: theme.palette.text.primary,
    textDecoration: "none",
  },
  legend: {
    backgroundColor: "white",
  },
  title: {
    textAlign: "left",
  },
});

class Month extends Component {
  constructor(props) {
    super(props);

    this.state = {
      route: "",
      isLoading: true,
      month: "",
      data: [],
      previousMonthUrl: "",
      nextMonthUrl: "",
    };
  }

  componentDidMount() {
    const route = window.location.pathname;
    if (route !== null && route !== this.state.route) {
      if (this.state.route !== route) {
        this.setState({ route: route });

        var splittedRoute = route.split("/");

        if (splittedRoute.length < 4) {
          var now = new Date();
          redirect(
            this.getCalendarUrl(
              now.getUTCFullYear(),
              parseInt(now.getUTCMonth() + 1, 10)
            )
          );
        }

        var currentMonth = new Date(splittedRoute[2], splittedRoute[3]);
        this.setState({
          previousMonthUrl: this.getCalendarUrl(
            currentMonth.getUTCFullYear(),
            parseInt(currentMonth.getUTCMonth(), 10)
          ),
          nextMonthUrl: this.getCalendarUrl(
            currentMonth.getUTCFullYear(),
            parseInt(currentMonth.getUTCMonth() + 2, 10)
          ),
        });

        getEventsForMonth(splittedRoute[2], splittedRoute[3]).then(
          (response) => {
            this.setState({
              data: response.data.events,
              month: response.data.month,
              isLoading: false,
            });
          }
        );
      }
    }
  }

  getCalendarUrl(year, month) {
    if (month === 0) {
      year = parseInt(year, 10) - 1;
      month = 12;
    } else if (month === 13) {
      year = parseInt(year, 10) + 1;
      month = 1;
    }

    return "/kalender/" + year + "/" + month;
  }

  render() {
    return (
      <OneColumnContainer>
        <Grid container>
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1" className={this.props.classes.title}>
              Aktiviteter under {this.state.month}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ActivityList data={this.state.data} fullPage={true} />
            <div className={this.props.classes.legend}>
              <Legend />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              href={this.state.previousMonthUrl}
            >
              Visa Föregående månad
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              variant="outlined"
              color="secondary"
              href={this.state.nextMonthUrl}
            >
              Visa Nästa månad
            </Button>
          </Grid>
        </Grid>
      </OneColumnContainer>
    );
  }
}

export default withStyles(styles)(Month);
