import React, { Component } from 'react';
import { CheckStrokeIcon } from '../Icons/Icons';

class TrainingGroups extends Component {
    render() {
        return (
            <span>
                {this.props.groups.map(function (trainingGroup, index) {
                    return (
                        <CheckStrokeIcon key={index} size={this.props.size} rgbColor={trainingGroup} />
                    )
                }, this)}
            </span>
        )
    }
}

export default TrainingGroups;