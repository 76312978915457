import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    close: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
});

class Popup extends Component {
    constructor(props) {
        super(props)

        this.handleRequestClose = this.handleRequestClose.bind(this);
    }

    handleRequestClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        if (this.props.onClosing) {
            this.props.onClosing();
        }
    };

    render() {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={this.props.open}
                    onClose={this.handleRequestClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.message}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={this.props.classes.close}
                            onClick={this.handleRequestClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </div>
        )
    }
}

export default withStyles(styles)(Popup);