import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  contentContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  content: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
});

class OneColumnContainer extends Component {
  render() {
    const { children, classes } = this.props;
    return (
      <Paper className={classes.contentContainer}>
        <div className={classes.content}>{children}</div>
      </Paper>
    );
  }
}

export default withStyles(styles)(OneColumnContainer);
