import React, { Component } from "react";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import Activity from "./Activity";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  listSection: {
    background: "inherit",
  },
  listSectionFullPage: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0,
    paddingTop: 0,
  },
  dayTitle: {
    marginTop: theme.spacing(1),
    color: "#000",
  },
  subheading: {
    paddingLeft: 0,
  },
});

class Day extends Component {
  render() {
    return (
      <div
        className={
          this.props.fullPage
            ? this.props.classes.listSectionFullPage
            : this.props.classes.listSection
        }
      >
        <ListSubheader className={this.props.classes.subheading}>
          <Typography
            className={this.props.classes.dayTitle}
            variant="subtitle1"
          >
            {this.props.day.date}
          </Typography>
        </ListSubheader>
        {this.props.day.events.map(function (event, index) {
          return <Activity key={event.id} event={event} />;
        }, this)}
      </div>
    );
  }
}

export default withStyles(styles)(Day);
