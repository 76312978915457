import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { postEvents, getTrainingGroups, putEvents } from '../../util/apicalls';
import Popup from '../Alert/Popup';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '90%'
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary
    },
    date: {
        minWidth: 50
    }
});

class EditForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name: '',
            time: '00:00',
            date: 1,
            description: '',
            eventorId: '',
            trainingGroups: [],
            openErrorAlert: false,
            isError: false,
            year: 1,
            month: 1,
            eventId: null,
            forceUploadTicker: 1
        }

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.hasTrainingGroup = this.hasTrainingGroup.bind(this);
    }
    componentDidMount() {
        var splittedRoute = this.props.route.split('/');

        if (splittedRoute[2] !== this.state.year || splittedRoute[3] !== this.state.month) {
            this.setState({
                year: splittedRoute[2],
                month: splittedRoute[3]
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.editEvent !== this.props.editEvent) {
            this.setState({
                eventId: nextProps.editEvent.id,
                name: nextProps.editEvent.name,
                description: nextProps.editEvent.description,
                time: nextProps.editEvent.time,
                date: nextProps.editEvent.date,
                eventorId: nextProps.editEvent.eventorId !== null ? nextProps.editEvent.eventorId : '',
                trainingGroups: nextProps.editEvent.trainingGroups, //<- this as well
                forceUploadTicker: this.state.forceUploadTicker + 1
            });
        }
    }

    handleChange(event, name) {
        if (name.startsWith('trainingGroup-')) {
            var tg = this.state.trainingGroups;

            var index = tg.indexOf(event.target.value);
            if (index === -1) {
                tg.splice(0, 0, event.target.value);
            } else {
                tg.splice(index, 1);
            }

            this.setState({ trainingGroups: tg });
        } else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    hasTrainingGroup(group) {
        var index = this.state.trainingGroups.indexOf(group);
        return index !== -1;
    }

    save() {
        if (
            this.state.name.length === 0 ||
            this.state.date.length === 0
        ) {
            this.setState({ isError: true });
            return;
        }

        this.props.isSaving(true);

        var splittedRoute = this.props.route.split('/');
        var year = splittedRoute[2];
        var month = splittedRoute[3];

        var date = year + '-' +
            (month < 10 ? '0' + month : month) + '-' +
            (this.state.date < 10 ? '0' + this.state.date : this.state.date);

        var promise = this.state.eventId === null ?
            postEvents(this.state.name, date, this.state.time, this.state.trainingGroups, this.state.description, this.state.eventorId) :
            putEvents(this.state.eventId, this.state.name, date, this.state.time, this.state.trainingGroups, this.state.description, this.state.eventorId);

        promise.then(response => {
            if (response === false) {
                this.setState({ openErrorAlert: true });
            } else {
                this.setState({
                    name: '',
                    time: '00:00',
                    date: 1,
                    description: '',
                    eventorId: '',
                    trainingGroups: [],
                    eventId: null
                });
            }

            this.props.isSaving(false);
        }).catch(error => {
            this.setState({ openErrorAlert: true });
            this.props.isSaving(false);
        });
    }

    closePopup() {
        this.setState({ openErrorAlert: false });
    }

    render() {

        return (
            <Paper className={this.props.classes.paper}>
                <Typography variant="h2">Lägg till aktivitet</Typography>
                <Grid container >
                    <Grid item xs={12}>
                        <TextField
                            required
                            error={this.state.isError && this.state.name.length === 0}
                            id="name"
                            label="Namn på aktivitet"
                            className={this.props.classes.textField}
                            onChange={(e) => this.handleChange(e, 'name')}
                            margin="normal"
                            value={this.state.name}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            required
                            error={this.state.isError && this.state.date.length === 0}
                            startAdornment={<InputAdornment className={this.props.classes.date} position="start">{`${this.state.year}-${this.state.month}-`}</InputAdornment>}
                            id="date"
                            label="Dag"
                            type="number"
                            min="1"
                            max="31"
                            value={this.state.date}
                            className={this.props.classes.dateField}
                            onChange={(e) => this.handleChange(e, 'date')}

                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="time"
                            label="Tid"
                            type="time"
                            value={this.state.time}
                            className={this.props.classes.textField}
                            onChange={(e) => this.handleChange(e, 'time')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{
                                step: 1500, // 15 min
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="eventorId"
                            label="Eventor-id"
                            type="number"
                            value={this.state.eventorId}
                            className={this.props.classes.textField}
                            onChange={(e) => this.handleChange(e, 'eventorId')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            helperText="eventor.orientering.se/Events/Show/DETTANUMMER"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Träningsgrupper</Typography>
                        <div data-force-upload={this.state.forceUploadTicker}>
                            {getTrainingGroups().map(function (x) {

                                return (
                                    <FormControlLabel key={x.value}
                                        control={
                                            <Checkbox
                                                onChange={(e) => this.handleChange(e, 'trainingGroup-' + x.key)}
                                                checked={this.hasTrainingGroup(x.key)}
                                                value={x.key}
                                            />
                                        }
                                        label={x.value}
                                    />
                                );
                            }, this)}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description"
                            label="Beskrivning"
                            onChange={(e) => this.handleChange(e, 'description')}
                            multiline
                            rows="10"
                            value={this.state.description}
                            className={this.props.classes.textField}
                            margin="normal"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.save}>
                            Spara
                        </Button>
                    </Grid>
                </Grid>
                <Popup message="Något gick snett! Är datum korrekt?" open={this.state.openErrorAlert} onClosing={this.closePopup} />
            </Paper>
        )
    }
}

export default withStyles(styles)(EditForm);