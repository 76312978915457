import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Find from '../Files/Find';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography/Typography';
import { getContent, isLoggedIn, redirect } from '../../util/apicalls';

const styles = theme => ({
    findContainer: {
        flex: '0 0 auto',
        margin: 0,
        padding: '24px 24px 20px 24px'
    },
    link: {
        textTransform: 'none',
        minWidth: 'auto',
        minHeight: 'auto',
        padding: 0
    }
});

class FileDialog extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tags: []
        };

        this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
        this.props.closeCallback();
    };

    componentDidMount() {
        if (!isLoggedIn()) {
            redirect('/login');
        }

        if (this.state.tags.length === 0 || this.refs.fileRef) {
            getContent('/content/tags').then(response => {
                var tags = response.data.content.map(function (tag) {
                    return `${tag.group} / ${tag.name}`
                });
                tags.sort();
                this.setState({ tags: tags });
            });
        }
    }

    render() {
        return (
            <Dialog ref="fileRef" open={this.props.open} onClose={this.handleClose}>
                <Grid container className={this.props.classes.findContainer}>
                    <Grid item xs={12}>
                        <Typography variant="h2">Sök efter filer med viss tagg</Typography>
                        <Typography variant="body1">Här kan du sätta in en fil i dokumentet. Saknar du dokumentet går du till <Button color="secondary" className={this.props.classes.link} href="/filer">filer</Button> för att ladda upp dokumentet först</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Find tags={this.state.tags} viewInsert selectCallback={this.props.fileInsertCallback} />
                    </Grid>
                </Grid>
            </Dialog>
        )
    }
}

export default withStyles(styles)(FileDialog);