import React, { Component } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import TrainingGroups from "./TrainingGroups";
import Description from "./Description";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  expanded: {
    backgroundColor: "#e0e0e0",
    marginTop: theme.spacing(1),
  },
  expandArea: {
    border: "1px solid #e0e0e0",
    borderTop: 0,
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  hidden: {
    display: "none",
  },
  primary: {
    fontSize: theme.typography.body1.fontSize,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: "none",
  },
  eventorLink: {
    minHeight: "inherit",
    padding: 0,
    textTransform: "none",
    width: "100%",
    justifyContent: "left",
  },
});

class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.getSecondaryText = this.getSecondaryText.bind(this);
    this.getEventorLink = this.getEventorLink.bind(this);
  }

  handleClick() {
    if (this.props.event.hasDescription) {
      this.setState({ open: !this.state.open });
    }
  }

  render() {
    return (
      <div>
        <ListItem
          divider
          dense
          className={this.state.open ? this.props.classes.expanded : ""}
          button
          onClick={this.handleClick}
        >
          <ListItemText
            primary={this.props.event.name}
            secondary={this.getSecondaryText(this.props.event)}
            classes={{ primary: this.props.classes.primary }}
          />
          <TrainingGroups groups={this.props.event.trainingGroups} size="21" />
          {this.props.event.hasDescription ? (
            this.state.open ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : (
            ""
          )}
        </ListItem>
        <Collapse in={this.state.open} unmountOnExit>
          <div className={this.props.classes.expandArea}>
            <Typography variant="body1">
              <Description text={this.props.event.description} />
            </Typography>
          </div>
        </Collapse>
      </div>
    );
  }

  getSecondaryText() {
    if (this.props.event.hasStartTime && this.props.event.hasEventorLink) {
      return (
        <span>
          {this.props.event.startTime} - {this.getEventorLink()}
        </span>
      );
    }
    if (this.props.event.hasEventorLink) {
      return this.getEventorLink();
    }
    if (this.props.event.hasStartTime) {
      return <span>{this.props.event.startTime}</span>;
    }

    return "";
  }

  getEventorLink() {
    return (
      <Button
        color="secondary"
        className={this.props.classes.eventorLink}
        href={this.props.event.eventorLink}
      >
        Länk till Eventor
      </Button>
    );
  }
}

export default withStyles(styles)(Activity);
