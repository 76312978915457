import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { getNewsFromYear } from "../../util/apicalls";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import { NewsIcon } from "../Icons/Icons";
import Button from "@material-ui/core/Button";
import OneColumnContainer from "../OneColumnContainer";

const styles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  edit: {
    textAlign: "right",
    paddingRight: theme.spacing(1),
  },
  loaderEnabled: {
    display: "block",
  },
  loaderDisabled: {
    display: "none",
  },
  hidden: {
    display: "none",
  },
  link: {
    textTransform: "none",
    minWidth: "auto",
    minHeight: "auto",
    padding: 2,
    marginLeft: -2,
  },
  row: {
    paddingTop: theme.spacing(1),
  },
  title: {
    textAlign: "left",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  editBar: {
    textAlign: "right",
  },
});

class NewsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      year: 0,
      data: [],
    };
  }

  componentDidMount() {
    const route = window.location.pathname;
    if (route !== null && this.state.year === 0) {
      var splittedRoute = route.split("/");
      var year = splittedRoute[2];

      if (year === undefined || parseInt(year, 10) === 0) {
        var now = new Date();
        year = now.getUTCFullYear();
      }

      getNewsFromYear(year).then((response) => {
        this.setState({
          data: response.data,
          isLoading: false,
          year: year,
        });
      });
    }
  }

  render() {
    return (
      <OneColumnContainer>
        <Grid container justify="center">
          <Grid item xs={12}>
            <LinearProgress
              className={
                this.state.isLoading
                  ? this.props.classes.loaderEnabled
                  : this.props.classes.loaderDisabled
              }
            />
            <Typography variant="h1" className={this.props.classes.title}>
              Nyheter från {this.state.year} <NewsIcon size="20" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {this.state.data &&
              this.state.data.map(function (item, index) {
                return (
                  <div key={index} className={this.props.classes.item}>
                    <Typography variant="h2" align="left">
                      {item.title}
                    </Typography>
                    <Typography variant="body2">{item.description}</Typography>
                    {!item.hasContent && item.link && item.link.length > 0 ? (
                      <Button
                        href={item.link}
                        className={this.props.classes.link}
                        color="secondary"
                      >
                        LÄNK
                      </Button>
                    ) : (
                      ""
                    )}
                    {item.hasContent ? (
                      <Button
                        href={item.url}
                        className={this.props.classes.link}
                        color="secondary"
                      >
                        LÄS MER
                      </Button>
                    ) : (
                      ""
                    )}
                    <Typography variant="caption">
                      ({item.timestamp})
                    </Typography>
                  </div>
                );
              }, this)}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="secondary"
              href={`/nyheter/${parseInt(this.state.year, 10) - 1}`}
            >
              Nyheter från {parseInt(this.state.year, 10) - 1}
            </Button>{" "}
            &nbsp;&nbsp;
            <Button
              variant="outlined"
              color="secondary"
              href={`/nyheter/${parseInt(this.state.year, 10) + 1}`}
            >
              Nyheter från {parseInt(this.state.year, 10) + 1}
            </Button>
          </Grid>
        </Grid>
      </OneColumnContainer>
    );
  }
}

export default withStyles(styles)(NewsList);
