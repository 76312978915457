import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  resetPassword,
  isLoggedIn,
  redirect,
  login,
} from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../Alert/Loader";
import Popup from "../Alert/Popup";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  row: {
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
  info: {
    maxWidth: 250,
    margin: "auto",
  },
});

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail: "",
      key: "",
      password: "",
      isLoading: false,
      openAlert: false,
      alertMessage: "",
      isError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    if (isLoggedIn()) {
      redirect("/");
    }

    const route = window.location.pathname;
    if (route !== null && this.state.mail === "") {
      var splittedRoute = route.split("/");

      this.setState({
        mail: decodeURIComponent(splittedRoute[2]),
        key: decodeURIComponent(splittedRoute[3]),
      });
    }
  }

  handleChange(value) {
    if (this.state.mail.length === 0) {
      this.setState({ isError: true });
      return;
    }

    this.setState({ isLoading: true });

    resetPassword(this.state.mail, this.state.password, this.state.key).then(
      (result) => {
        if (result) {
          login(this.state.mail, this.state.password).then((result) => {
            if (result) {
              redirect("/");
            } else {
              this.setState({
                openAlert: true,
                alertMessage: "Något gick galet!",
              });
            }
          });
        } else {
          this.setState({
            openAlert: true,
            alertMessage: "Något gick fel, är ditt mail gammalt?",
          });
        }

        this.setState({ isLoading: false });
      }
    );
  }
  onPasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  closePopup() {
    this.setState({ openAlert: false });
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1">Återställ lösenord</Typography>
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <TextField
              required
              error={this.state.isError && this.state.password.length === 0}
              id="password"
              label="Ange nytt lösenord"
              placeholder="Ange nytt lösenord"
              className={this.props.classes.textField}
              type="password"
              margin="normal"
              onChange={this.onPasswordChange}
            />
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              onClick={this.handleChange}
            >
              Byt lösenord
            </Button>
          </Grid>
        </Grid>
        <Popup
          message={this.state.alertMessage}
          open={this.state.openAlert}
          onClosing={this.closePopup}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ResetPassword);
