import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { recoverPassword, isLoggedIn, redirect } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loader from "../Alert/Loader";
import Popup from "../Alert/Popup";

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  row: {
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
  button: {
    margin: theme.spacing(1),
  },
  info: {
    maxWidth: 250,
    margin: "auto",
    marginTop: theme.spacing(1),
    textAlign: "left",
  },
});

class RecoverPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mail: "",
      password: "",
      isLoading: false,
      openAlert: false,
      alertMessage: "",
      isError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onMailChange = this.onMailChange.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentDidMount() {
    if (isLoggedIn()) {
      redirect("/");
    }
  }

  handleChange(value) {
    if (this.state.mail.length === 0) {
      this.setState({ isError: true });
      return;
    }

    this.setState({ isLoading: true });

    recoverPassword(this.state.mail).then((result) => {
      if (result) {
        this.setState({
          openAlert: true,
          alertMessage: "Ett mail har skickats till din e-post!",
        });
      } else {
        this.setState({
          openAlert: true,
          alertMessage: "Något gick fel, du kanske redan har en användare?",
        });
      }

      this.setState({ isLoading: false });
    });
  }

  onMailChange(event) {
    this.setState({ mail: event.target.value });
  }

  closePopup() {
    this.setState({ openAlert: false });
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Loader isLoading={this.state.isLoading} />
            <Typography variant="h1">Glömt ditt lösenord?</Typography>
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <TextField
              required
              error={this.state.isError && this.state.mail.length === 0}
              id="mail"
              label="Ange e-post"
              placeholder="Ange e-post"
              className={this.props.classes.textField}
              margin="normal"
              onChange={this.onMailChange}
            />
          </Grid>

          <Grid item xs={12} className={this.props.classes.row}>
            <Button
              variant="contained"
              color="primary"
              className={this.props.classes.button}
              onClick={this.handleChange}
            >
              Återställ lösenord
            </Button>
          </Grid>
          <Grid item xs={12} className={this.props.classes.row}>
            <Typography variant="body1" className={this.props.classes.info}>
              När du tryckt på knappen kommer du få ett e-post skickat till dig
              med en länk för att återställa lösenordet.
            </Typography>
            <Typography variant="body1" className={this.props.classes.info}>
              E-post måste sedan tidigare var registrerad. Har ditt konto varit
              inaktivt över ett år har det tagits bort.
            </Typography>
          </Grid>
        </Grid>
        <Popup
          message={this.state.alertMessage}
          open={this.state.openAlert}
          onClosing={this.closePopup}
        />
      </div>
    );
  }
}

export default withStyles(styles)(RecoverPassword);
