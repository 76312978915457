import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { getContent } from "../../util/apicalls";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { DeerIcon } from "../Icons/Icons";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  headline: {
    textTransform: "uppercase",
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.secondary["A700"],
    textDecoration: "none",
  },
  hidden: {
    display: "none",
  },
});

class Competitions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        competitions: [],
      },
      firstLoad: true,
    };
  }

  componentDidMount() {
    if (this.state.firstLoad) {
      this.props.isLoadingCallback("competitions", true);

      getContent("/content/competitions").then((response) => {
        this.setState({
          data: JSON.parse(response.data.content),
          firstLoad: false,
        });
        this.props.isLoadingCallback("competitions", false);
      });
    }
  }

  render() {
    return (
      <Paper
        className={
          this.state.data.competitions.length > 0
            ? this.props.classes.paper
            : this.props.classes.hidden
        }
      >
        <Grid container>
          {this.state.data.competitions &&
            this.state.data.competitions.map(function (item, index) {
              return (
                <Grid key={index} item xs={12} spacing={1}>
                  <Typography
                    variant="h4"
                    className={this.props.classes.headline}
                  >
                    {item.name} <DeerIcon width="38" height="19" />
                  </Typography>
                  <Typography variant="caption">{item.date}</Typography>
                  <br />
                  <Button
                    variant="outlined"
                    color="secondary"
                    href={`https://eventor.orientering.se/Events/Show/${item.eventorId}`}
                  >
                    Eventor
                  </Button>
                  <br /><br />
                </Grid>
              );
            }, this)}
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Competitions);
