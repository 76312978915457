import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { PencilIcon } from "../Icons/Icons";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
});

class Events extends Component {
  render() {
    return (
      <Paper className={this.props.classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h4">
              Anmälningar <PencilIcon size="20" />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              Anmälan till interna aktiviteter sker via Eventor
            </Typography>
            <br />
            <Button
              variant="outlined"
              color="secondary"
              href="https://eventor.orientering.se/Activities?organisationId=341"
            >
              Gå till Eventor
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(Events);
