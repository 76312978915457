import React, { Component } from "react";

class Description extends Component {
  render() {
    return (
      <span>
        {this.props.text.split("\n").map(function (str, index) {
          return (
            <span key={index}>
              {str}
              <br />
            </span>
          );
        })}
      </span>
    );
  }
}

export default Description;
