import React, { Component } from 'react';
import FileDialog from './FileDialog';
import { CopyIcon } from '../Icons/Icons';

var allEditors;

class Editor extends Component {
  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      this.ReactQuill = require('react-quill')
    }

    this.state = {
      dialogOpen: false,
      currentCursorPosition: 0
    }

    this.onChange = this.onChange.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.insertFileLink = this.insertFileLink.bind(this);

    this.modules = {
      toolbar: {
        container: `#editor-toolbar-${this.props.column}-${this.props.row}`,
        handlers: {
          "insertFile": this.insertFile,
        }
      },
      clipboard: {
        matchVisual: false
      }
    };

    this.formats = ['header', 'list', 'link'];

    if (!Array.isArray(allEditors)) {
      allEditors = [];
    }

    allEditors.push(this);
  }

  onChange(content, delta, source, editor) {
    var newData = {
      column: parseInt(this.props.column, 10),
      row: parseInt(this.props.row, 10),
      data: editor.getContents()
    };

    this.props.onChange(newData);
  }

  closeDialog() {
    this.setState({ dialogOpen: false });
  }

  insertFileLink(id, name) {
    let editor = this.editor.getEditor();
    editor.insertText(this.state.currentCursorPosition, name, 'link', '###' + id);
    editor.setSelection(this.state.currentCursorPosition + name.length);
    this.setState({ dialogOpen: false });
  }

  insertFile() {
    var editor = allEditors.find(function (element) {
      return `#editor-toolbar-${element.props.column}-${element.props.row}` === this.options.container;
    }, this);

    //this is in the context of quill now
    editor.setState({
      dialogOpen: true,
      currentCursorPosition: this.quill.getSelection().index
    });
  }

  render() {
    const ReactQuill = this.ReactQuill
    if (typeof window !== 'undefined' && ReactQuill) {
      return (
        <div>
          <div id={`editor-toolbar-${this.props.column}-${this.props.row}`}>
            <select className="ql-header" defaultValue="4">
              <option value="2">Rubrik 1</option>
              <option value="3">Rubrik 2</option>
              <option value="4">Text</option>
            </select>
            <button className="ql-link" />
            <button className="ql-list" value="bullet" />
            <button className="ql-clean" />
            <button className="ql-insertFile">
              <CopyIcon size="16" />
            </button>
          </div>
          <ReactQuill ref={(editor) => { this.editor = editor; }} onChange={this.onChange} theme="snow" defaultValue={this.props.text} modules={this.modules} formats={this.formats} />
          <FileDialog fileInsertCallback={this.insertFileLink} open={this.state.dialogOpen} closeCallback={this.closeDialog} />
        </div>
      )
    } else {
      return <textarea />;
    }
  }
}

export default Editor;