import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { deleteEvent, reload } from '../../util/apicalls';
import Typography from '@material-ui/core/Typography';
import Popup from '../Alert/Popup';

const styles = theme => ({
    headline: {
        textTransform: 'uppercase'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary
    }
});

class EditActivity extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activity: '',
            openAlert: false,
            popupMessage: ''
        }

        this.handleChange = this.handleChange.bind(this);
        this.delete = this.delete.bind(this);
        this.activitiesToArray = this.activitiesToArray.bind(this);
        this.edit = this.edit.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    handleChange(event) {
        this.setState({
            activity: event.target.value
        });
    };

    edit() {
        this.props.edit(this.state.activity);
    }

    activitiesToArray() {
        var items = [];

        this.props.activities.forEach(function (day) {
            day.events.forEach(function (item) {
                if (item.id) {
                    items.push({
                        name: day.date + ' - ' + item.name,
                        id: item.id
                    });
                }
            })
        })

        return items;
    }

    delete() {
        if (this.state.activity !== '') {
            deleteEvent(this.state.activity)
                .then(response => {
                    reload();
                })
                .catch(error => {
                    this.setState({
                        mailAddress: '',
                        isLoading: false,
                        popupMessage: 'Aktiviteten gick inte att ta bort',
                        openAlert: true
                    });
                });
        }
    }

    closePopup() {
        this.setState({ openAlert: false });
    }

    render() {

        return (
            <Paper className={this.props.classes.paper}>
                <Typography variant="h2">Redigera eller ta bort aktivitet</Typography>
                <TextField
                    id="eventToRemove"
                    select
                    label="Aktivitet att ta bort"
                    className={this.props.classes.textField}
                    value={this.state.activity}
                    SelectProps={{
                        MenuProps: {
                            className: this.props.classes.menu,
                        },
                    }}
                    onChange={(e) => this.handleChange(e)}
                    margin="normal"
                >
                    {this.props.activities && this.activitiesToArray().map(function (item, index) {
                        return (
                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                        )
                    }, this)}
                </TextField>
                <br /><br />
                <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.edit}>
                    REDIGERA AKTIVITET OVAN
                </Button>
                <br /><br /><br /><br />
                <Typography variant="body1">OBS! Aktiviteten kommer att tas bort direkt och går inte att ångra</Typography>
                <Button variant='contained' color="secondary" className={this.props.classes.button} onClick={this.delete}>
                    TA BORT AKTIVITET OVAN
                </Button>
                <Popup message={this.state.popupMessage} open={this.state.openAlert} onClosing={this.closePopup} />
            </Paper>
        )
    }
}

export default withStyles(styles)(EditActivity);