import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
      marginTop: theme.spacing(1),
      
  },
  ww: {
      width: '100%'
  },
  wwContainer: {
    textAlign: 'center'
  }
});

class NotFound extends Component {

  render() {
    return (
      <Paper className={this.props.classes.paper}>
      <Grid container justify="center">
        <Grid item xs={12} sm={8}>          
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h1">Ooops! Du har sprungit helt vilse!</Typography>
                <Typography variant="body1">Orientering kan vara väldigt lurigt ibland. Om du tränar tillräckligt målmedvetet så kanske du till slut blir lika bra som killen nedan som växlade i täten på 10mila 2002. Tills dess kan du klicka dig vidare till startsidan genom att klicka på klubbnamnet ovan.</Typography>
                <div className={this.props.classes.wwContainer}>
                  <img className={this.props.classes.ww} src="/img/winningw.jpg" alt="Vinnare"  />
                </div>
              </Grid>
            </Grid>          
        </Grid>
      </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(NotFound);