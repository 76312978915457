import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { getLatestNews, updateNews } from '../../util/apicalls';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '85%'
    },
    hidden: {
        display: 'none'
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary
    },
    row: {
        paddingBottom: theme.spacing(2)
    }
});

class UpdateNews extends Component {

    constructor(props) {
        super(props)

        this.state = {
            news: '',
            data: []
        }

        this.handleChange = this.handleChange.bind(this);
        this.edit = this.edit.bind(this);
        this.unpublish = this.unpublish.bind(this);
    }

    componentDidMount() {
        if (this.state.data.length === 0) {
            getLatestNews(40).then(response => {
                this.setState({ data: response.data });
            });
        }
    }

    handleChange(event) {
        this.setState({
            news: event.target.value
        });
    };

    edit() {
        if (this.state.news !== '') {
            var splittedRoute = this.state.news.split('/');
            this.props.editNewsCallback(splittedRoute[2], splittedRoute[3]);
        }
    }

    unpublish() {
        if (this.state.news !== '') {
            var splittedRoute = this.state.news.split('/');
            updateNews(splittedRoute[2], splittedRoute[3], null, null, null, false);
        }
    }

    render() {
        return (
            <div>
                <Typography variant="h2">Redigera eller ta bort nyhet</Typography>
                <Typography variant="body1">Nedan kan du redigera eller ta bort befintliga nyheter.<br /> Redigerad nyhet hamnar alltid längst upp i listningen</Typography>
                <TextField
                    id="newsPicker"
                    select
                    label="Nyhet att redigera eller avpublicera"
                    className={this.props.classes.textField}
                    value={this.state.news}
                    SelectProps={{
                        MenuProps: {
                            className: this.props.classes.menu,
                        },
                    }}
                    onChange={(e) => this.handleChange(e)}
                    margin="normal"
                >
                    {this.state.data && this.state.data.map(function (item, index) {
                        return (
                            <MenuItem key={index} value={item.url}>{item.title}</MenuItem>
                        )
                    }, this)}
                </TextField><br /><br />
                <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.edit}>
                    REDIGERA
                </Button><br /><br />
                <Button variant='contained' color="secondary" className={this.props.classes.button} onClick={this.unpublish}>
                    AVPUBLICERA
                </Button>
            </div>
        )
    }
}

export default withStyles(styles)(UpdateNews);