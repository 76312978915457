import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    button: {
        margin: theme.spacing(1),
    },
    hidden: {
        display: 'none'
    },
    editBar: {
        textAlign: 'right'
    }
});

class EditPanel extends Component {
    constructor(props) {
        super(props)

        this.addRow = this.addRow.bind(this);
        this.removeRow = this.removeRow.bind(this);
    }

    addRow() {
        this.props.addRowCallback(this.props.index);
    }

    removeRow() {
        this.props.removeRowCallback(this.props.index);
    }

    render() {
        return (
            <Grid item xs={12} className={this.props.classes.editBar}>
                <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.addRow}>
                    Lägg till block ovanför
                </Button>
                <Button variant='contained' color="secondary" className={this.props.hideRemoveButton ? this.props.classes.hidden : this.props.classes.button} onClick={this.removeRow}>
                    Ta bort detta block
                </Button>
            </Grid>
        )
    }
}

export default withStyles(styles)(EditPanel);