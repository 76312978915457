import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { createNews, updateNews, redirect, getNews, isLoggedIn } from '../../util/apicalls';
import Popup from '../Alert/Popup';
import Loader from '../Alert/Loader';
import Editor from '../Editor/Editor';
import Paper from '@material-ui/core/Paper';
import UpdateNews from './UpdateNews';

const styles = theme => ({     
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '85%'
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(2)
    },
    row: {
        paddingBottom: theme.spacing(2)
    },
    rowDelete: {
        marginTop: theme.spacing(10),
        borderTop: '1px #ccc solid',
        paddingTop: theme.spacing(2)
    }
});

class CreateNewsForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            year: undefined,
            id: undefined,
            openErrorAlert: false,
            isLoading: false,
            title: '',
            description: '',
            content: '',
            link: '',
            isError: false,
            reload: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.editorCallback = this.editorCallback.bind(this);
        this.save = this.save.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.editNews = this.editNews.bind(this);
    }

    componentDidMount() {
        if (!isLoggedIn()) {
            redirect('/login');
        }
    }

    handleChange(event, name) {
        this.setState({
            [name]: event.target.value
        });
    };

    editorCallback(data) {
        this.setState({ content: data.data });
    }

    editNews(year, id) {
        this.setState({ isLoading: true, reload: true });

        getNews(year, id).then(response => {
            this.setState(
                {
                    year: year,
                    id: id,
                    title: response.data.title,
                    description: response.data.description,
                    link: response.data.link || '',
                    content: JSON.parse(response.data.content),
                    isLoading: false,
                    reload: false
                }
            );

            if (typeof (window) !== 'undefined') {
                window.scrollTo(0, 0);
            }
        });
    }

    save() {
        if (
            this.state.title.length === 0 ||
            this.state.description.length === 0
        ) {
            this.setState({ isError: true });
            return;
        }

        this.setState({ isLoading: true });

        var content = {
            content: this.state.content,
            link: this.state.link
        };

        if (!this.state.id) {
            createNews(this.state.title, this.state.description, content)
                .then(response => {
                    this.setState({ isLoading: false });

                    if (response === null) {
                        this.setState({ openErrorAlert: true });
                    } else {
                        redirect('/nyheter/' + response.url);
                    }
                });
        } else {
            updateNews(this.state.year, this.state.id, this.state.title, this.state.description, content, null);
            this.setState({ isLoading: false });
        }
    }

    closePopup() {
        this.setState({ openErrorAlert: false });
    }


    render() {

        return (
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Loader isLoading={this.state.isLoading} />
                    <Typography variant="h1">Skapa nyhet</Typography>
                </Grid>
                <Grid item xs={12} sm={10}>
                    <Grid container justify="center">
                        <Paper className={this.props.classes.paper}>
                            <Grid item xs={12} className={this.props.classes.row}>
                                <TextField
                                    required
                                    error={this.state.isError && this.state.title.length === 0}
                                    id="title"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    label="Rubrik"
                                    placeholder="Rubrik"
                                    value={this.state.title}
                                    className={this.props.classes.textField}
                                    onChange={(e) => this.handleChange(e, 'title')}
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.row}>
                                <TextField
                                    required
                                    error={this.state.isError && this.state.description.length === 0}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    id="description"
                                    label="Kortare innehåll"
                                    onChange={(e) => this.handleChange(e, 'description')}
                                    multiline
                                    rows="4"
                                    value={this.state.description}
                                    className={this.props.classes.textField}
                                    margin="normal"
                                    helperText="Texten visas på startsidan och i listning, men även på nyhetsidan"
                                />
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.row}>
                                <TextField
                                    id="link"
                                    label="Länk"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    onChange={(e) => this.handleChange(e, 'link')}
                                    placeholder="https://"
                                    value={this.state.link}
                                    className={this.props.classes.textField}
                                    margin="normal"
                                    helperText="Länk visas på startsida samt nyhetslistning"
                                />
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.row}>
                                <Typography variant="caption">Information som visas på egen sida</Typography>
                                {!this.state.reload ? <Editor text={this.state.content} row='0' column='0' onChange={this.editorCallback} /> : ''}
                                <Typography variant="caption">Egen sida finns endast om denna text är ifylld</Typography>
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.row}>
                                <Button variant='contained' color="primary" className={this.props.classes.button} onClick={this.save}>
                                    Spara och publicera
                                </Button>
                            </Grid>
                            <Grid item xs={12} className={this.props.classes.rowDelete}>
                                <UpdateNews editNewsCallback={this.editNews} />
                            </Grid>
                        </Paper>
                    </Grid>
                    <Popup message="Något gick snett vid sparande" open={this.state.openErrorAlert} onClosing={this.closePopup} />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(CreateNewsForm);