import axios from "axios";

var apiUrl = "https://skogshjortarna-cms.azurewebsites.net";
//var apiUrl = 'http://localhost:5000';

export function login(email, password) {
  return axios
    .post(apiUrl + "/token", {
      email: email,
      password: password,
    })
    .then((response) => {
      setLocalStorage("access_token", response.data.token);
      setLocalStorage("token_expires", response.data.expires);
      setLocalStorage("user_role", response.data.userRole);
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function recoverPassword(email) {
  email = encodeURIComponent(email);
  return axios
    .get(apiUrl + "/users/reset?email=" + email)
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function resetPassword(email, password, key) {
  return axios
    .put(apiUrl + "/users", {
      mailAddress: email,
      password: password,
      temporaryAccessKey: key,
    })
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function registerUser(email, password, key, firstName, lastName) {
  return axios
    .put(apiUrl + "/users", {
      mailAddress: email,
      password: password,
      temporaryAccessKey: key,
      firstName: firstName,
      lastName: lastName,
    })
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function isLoggedIn() {
  return getAccessToken() !== null;
}

export function logout() {
  purgeLocalStorage("access_token");
  purgeLocalStorage("token_expires");
  purgeLocalStorage("user_role");
}

export function getContent(url) {
  url = encodeURIComponent(url);
  return axios.get(apiUrl + "/content?url=" + url, getAuthHeader());
}

export function getTags() {
  return getLocalStorage("tags");
}

export function setTags(tags) {
  setLocalStorage("tags", tags);
}

export function getUpcomingEvents(days) {
  return axios.get(apiUrl + "/events?days=" + days);
}

export function getEventsForMonth(year, month) {
  return axios.get(apiUrl + "/events/" + year + "/" + month);
}

export function postEvents(
  name,
  date,
  time,
  trainingGroups,
  description,
  eventorId
) {
  time = time === undefined ? "" : time;

  return axios
    .post(
      apiUrl + "/events",
      {
        name: name,
        date: date + " " + time,
        trainingGroups: trainingGroups,
        description: description,
        eventorId: eventorId !== "" ? eventorId : null,
      },
      getAuthHeader()
    )
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function putEvents(
  eventId,
  name,
  date,
  time,
  trainingGroups,
  description,
  eventorId
) {
  time = time === undefined ? "" : time;

  return axios
    .put(
      apiUrl + "/events?id=" + eventId,
      {
        name: name,
        date: date + " " + time,
        trainingGroups: trainingGroups,
        description: description,
        eventorId: eventorId !== "" ? eventorId : null,
      },
      getAuthHeader()
    )
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function deleteEvent(id) {
  return axios.delete(apiUrl + "/events?id=" + id, getAuthHeader());
}

export function deleteEventEntry(eventId, uniqueId) {
  return axios.delete(
    apiUrl + "/internalevent/" + eventId + "/" + uniqueId,
    getAuthHeader()
  );
}

export function updateContent(url, content) {
  url = encodeURIComponent(url);
  return axios
    .put(
      apiUrl + "/content?url=" + url,
      {
        content: content,
      },
      getAuthHeader()
    )
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function getNews(year, id) {
  return axios.get(apiUrl + "/news/" + year + "/" + id, getAuthHeader());
}

export function getLatestNews(count) {
  return axios.get(apiUrl + "/news?count=" + count, getAuthHeader());
}

export function getNewsFromYear(year) {
  return axios.get(apiUrl + "/news/" + year, getAuthHeader());
}

export function createNews(title, description, content) {
  return axios
    .post(
      apiUrl + "/news",
      {
        title: title,
        description: description,
        content: content,
      },
      getAuthHeader()
    )
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      return null;
    });
}

export function updateNews(year, id, title, description, content, isPublished) {
  return axios.put(
    apiUrl + "/news/" + year + "/" + id,
    {
      title: title,
      description: description,
      content: content,
      isPublished: isPublished,
    },
    getAuthHeader()
  );
}

export function getEvents() {
  return axios.get(apiUrl + "/internalevent", getAuthHeader());
}

export function getEvent(id) {
  return axios.get(apiUrl + "/internalevent/" + id, getAuthHeader());
}

export function createEvent(name, description, date, lastEntryDate) {
  return axios
    .post(
      apiUrl + "/internalevent",
      {
        name: name,
        description: description,
        date: date,
        lastEntryDate: lastEntryDate,
      },
      getAuthHeader()
    )
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      return null;
    });
}

export function updateEvent(id, name, description, date, lastEntryDate) {
  return axios
    .put(
      apiUrl + "/internalevent/" + id,
      {
        name: name,
        description: description,
        date: date,
        lastEntryDate: lastEntryDate,
      },
      getAuthHeader()
    )
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function addEntry(id, name, message) {
  return axios
    .post(
      apiUrl + "/internalevent/" + id,
      {
        name: name,
        message: message,
      },
      getAuthHeader()
    )
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function uploadImage(file) {
  var data = new FormData();
  data.append("file", file);
  return axios.post(apiUrl + "/images", data, getAuthHeader());
}

export function uploadFile(file, tags) {
  var data = new FormData();
  data.append("file", file);
  data.append("tags", tags);

  axios
    .post(apiUrl + "/files", data, getAuthHeader())
    .then((response) => {
      return true;
    })
    .catch(function (error) {
      return false;
    });
}

export function findFiles(tags) {
  tags = encodeURIComponent(tags);
  return axios.get(apiUrl + "/files?tags=" + tags, getAuthHeader());
}

export function postForum(antiSpam, name, content) {
  return axios.post(apiUrl + "/forum", {
    name: name,
    content: content,
    antiSpam: antiSpam,
  });
}

export function getForum() {
  return axios.get(apiUrl + "/forum");
}

export function redirect(url) {
  if (canUseDOM()) {
    window.location.href = url;
  }
}

export function reload() {
  if (canUseDOM()) {
    window.location.reload();
  }
}

export function canUseDOM() {
  return !!(
    typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
  );
}

export function getUserRole() {
  if (!isLoggedIn()) {
    return null;
  }

  return getLocalStorage("user_role");
}

export function getTrainingGroups() {
  return [
    { key: "white", value: "Vit ungdom" },
    { key: "yellow", value: "Gul ungdom" },
    { key: "orange", value: "Orange ungdom" },
    { key: "violet", value: "Violett ungdom" },
    { key: "red", value: "Motionsträning" },
    { key: "black", value: "Juniorer/Seniorer" },
    { key: "blue", value: "HD35+" },
    { key: "brown", value: "Veteraner" },
  ];
}

export function getWeekly() {
  return axios.get(apiUrl + "/eventor/weekly", getAuthHeader());
}

export function createUser(mailAddress, userRole) {
  return axios.post(
    apiUrl + "/users",
    {
      mailAddress: mailAddress,
      userRole: userRole,
    },
    getAuthHeader()
  );
}

function getAuthHeader() {
  if (!isLoggedIn()) {
    return null;
  }

  return { headers: { Authorization: "Bearer " + getAccessToken() } };
}

function getAccessToken() {
  var access_token = getLocalStorage("access_token");

  if (access_token === null) {
    return null;
  }

  var expireDate = getLocalStorage("token_expires");
  if (new Date() > new Date(expireDate)) {
    purgeLocalStorage("access_token");
    purgeLocalStorage("token_expires");
    return null;
  }

  return access_token;
}

function getLocalStorage(key) {
  if (typeof localStorage !== "undefined") {
    return localStorage.getItem(key);
  } else {
    return null;
  }
}

function setLocalStorage(key, value) {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem(key, value);
  }
}

function purgeLocalStorage(key) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(key);
  }
}
