import React, { Component } from 'react';
import { findFiles } from '../../util/apicalls';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { FileIcon, DownloadIcon, CheckIcon } from '../Icons/Icons';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography/Typography';
import yellow from '@material-ui/core/colors/yellow';

const styles = theme => ({    
    row: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
        padding: theme.spacing(1),
        borderBottomColor: yellow[300],
    },
    action: {
        height: theme.spacing(3),
        padding: 0
    },
    hidden: {
        display: 'none'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 400,        
        marginBottom: theme.spacing(2)
    }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class Find extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tag: '',
            files: [],
            copyText: '',
            openAlert: false
        }

        this.onChange = this.onChange.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }

    onChange(event) {
        this.setState({ tag: event.target.value });

        if (this.props.isLoadingCallback) {
            this.props.isLoadingCallback(true);
        }

        findFiles(event.target.value).then(response => {
            this.setState({ files: response.data });
            if (this.props.isLoadingCallback) {
                this.props.isLoadingCallback(false);
            }
        });
    }

    selectFile(id, name) {
        if (this.props.selectCallback) {
            this.props.selectCallback(id, name);
        }
    }

    closePopup() {
        this.setState({ openAlert: false });
    }

    render() {
        return (
            <Grid container>               
                <Grid item xs={12}>
                    <FormControl className={this.props.classes.formControl}>
                        <InputLabel htmlFor="tags">Ange tagg</InputLabel>
                        <Select
                            value={this.state.tag}
                            onChange={(e) => this.onChange(e)}
                            input={<Input id="tags" />}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        width: 200,
                                    },
                                },
                            }}
                        >
                            {this.props.tags && this.props.tags.map(function (x) {
                                return (
                                    <MenuItem
                                        key={x}
                                        value={x.toLowerCase()}
                                    >
                                        {x}
                                    </MenuItem>
                                );
                            }, this)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {this.state.files && this.state.files.map(function (file, index) {
                        return (
                            <Typography key={index} variant="subtitle1" className={this.props.classes.row}><FileIcon size='16' /> {file.name}  <span><Button className={this.props.classes.action} href={`https://skogshjortarna-cms.azurewebsites.net/files/${file.id}`}><DownloadIcon size='16' /></Button> <Button className={this.props.viewInsert ? this.props.classes.action : this.props.classes.hidden} onClick={() => this.selectFile(file.id, file.name)}><CheckIcon size='16' /></Button></span></Typography>
                        )
                    }, this)}
                    <Typography className={this.state.files.length > 0 ? this.props.classes.hidden : ''} variant="body1">Ingen filer att visa, välj tagg för att söka</Typography>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(Find);